<template>
  <div>
    <div
      v-if="!isBetween"
      class="d-flex flex-row flex-wrap"
    >
      <div
        v-for="(v, idx) in computedValues"
        :key="idx + '_v'"
        class="d-flex flex-row mr-2 align-center"
      >
        <date-filters
          :readonly="readonly"
          :value="computedValues[idx]"
          @input="setValue(idx, $event)"
          low
          :label="$t('t.Date')"
          :rules="rules"
          class="value"
          ref="input"
        />
        <v-btn
          class="ml-2"
          v-if="computedValues.length != 1 && !readonly"
          icon
          small
          @click="removeValue(idx)"
        >
          <v-icon small>{{$icon('i.Delete')}}</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          v-if="idx === computedValues.length -1 && !readonly"
          icon
          small
          color="secondary"
          @click="addValue"
        >
          <v-icon small>{{$icon('i.Plus')}}</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else>
      <between-date
        :document.sync="computedValues"
        low
        :label="$t('t.Date')"
        class="value"
        ref="input"
        :readonly="readonly"
        :rules="rules"
      />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    BetweenDate: () => import('./between-date'),
    DateFilters: () => import('../../../date-filters')
  },
  data () {
    return {
      required: [v => (!this.lodash.isNil(v) && v !== '') || this.$t('t.IsRequired')],
      dataValues: [null],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  computed: {
    computedValues: {
      get () { return this.dataValues },
      set (v) {
        this.dataValues = v
        this.dataDocumentEmit()
      }
    },
    rules () {
      return [v => !!v?.length || this.$t('t.IsRequired')]
    }
  },
  methods: {
    emitDocument () {
      if (!this.lodash.isEqual(this.document, this.dataValues)) {
        this.$emit('update:document', this.lodash.cloneDeep(this.dataValues))
      }
    },
    addValue () {
      this.dataValues.push(null)
      this.dataDocumentEmit()
    },
    setValue (idx, value) {
      this.dataValues[idx] = this.lodash.cloneDeep(value)
      this.dataDocumentEmit()
    },
    removeValue (idx) {
      this.dataValues.splice(idx, 1)

      if (this.dataValues.length === 0) {
        this.dataValues = [null]
      }
      this.dataDocumentEmit()
    }
  },
  props: {
    document: Array,
    disabled: Boolean,
    dense: Boolean,
    isBetween: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataValues = v?.length ? this.lodash.cloneDeep(v) : [null]
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
